.string-container {
  position: relative;
  height: 50px;
}

.string-container-unmapped {
  display: block;
  width: 100%;
}

.string-title {
  position: absolute;
  width: 175px;
  text-align: center;
  font-size: x-large;
  font-weight: bold;
}

.string-title-unmapped {
  text-align: center;
  font-size: x-large;
  font-weight: bold;
}

.sitemap-selector-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sitemap-selector-button {
  font-size: 20px;
  font-weight: bold;
  border: 3px solid;
  border-radius: 5px;
  margin: 5px;
  color: black;
  background: lavender;
}

.options-button {
  font-size: 16px;
  font-weight: bold;
  border: 3px solid;
  border-radius: 5px;
  margin: 5px;
  color: black;
  background: lavender;
}

.inverter-container {
  position: absolute;
  width: 175px;
  height: 50px;
  border: 2px solid black;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  font-size: medium;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 1px;
  margin-right: 1px;
}

.inverter-container-unmapped {
  display: inline-block;
  width: 175px;
  height: 50px;
  border: 2px solid black;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  font-size: medium;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 1px;
  margin-right: 1px;
}

.serial-number {
  float: left;
  width: 60%;
  font-size: small;
  font-weight: bold;
}

.ac-watts-prod {
  width: 100%;
  font-size: large;
  font-weight: bold;
  margin-top: 20px;
}

.string-position {
  float: right;
  text-align: end;
  width: 40%;
  font-size: small;
  font-weight: 900;
}

.string-id {
  float: right;
  margin-top: -17px;
  font-size: small;
  font-weight: bold;
}

.legend-divider {
  width: 80%;
  display: block;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 5px;
  background-color: black;
}

.legend-container {
  display: block;
  margin: auto;
  margin-top: 10px;
  width: 400px;
  border: 2px solid black;
}

.legend-item-container {
  display: flex;
  width: 100%;
  height: 40px;
}

.legend-text {
  margin: auto;
  margin-left: 0;
  font-size: 16px;
  color: black;
}

.white-box {
  margin: 9px;
  width: 40px;
  height: 20px;
  background-color: white;
  border: 1px solid black;
}

.light-yellow-box {
  margin: 9px;
  width: 40px;
  height: 20px;
  background-color: lightyellow;
  border: 1px solid black;
}

.moccasin-box {
  margin: 9px;
  width: 40px;
  height: 20px;
  background-color: moccasin;
  border: 1px solid black;
}

.light-gray-box {
  margin: 9px;
  width: 40px;
  height: 20px;
  background-color: lightgray;
  border: 1px solid black;
}

.gray-box {
  margin: 9px;
  width: 40px;
  height: 20px;
  background-color: gray;
  border: 1px solid black;
}

.red-box {
  margin: 9px;
  width: 40px;
  height: 20px;
  background-color: lightcoral;
  border: 1px solid black;
}

.red-outline-box {
  margin: 9px;
  width: 40px;
  height: 20px;
  background-color: white;
  border: 2px solid red;
}

.green-outline-box {
  margin: 9px;
  width: 40px;
  height: 20px;
  background-color: white;
  border: 2px solid green;
}